export function request(config) {
    var hasLoadingButton = config.loadingButton;
    if (hasLoadingButton) {
        startLoadingState(config.loadingButton);
    }
    const URL = config.isLocalUrl ? BASE_URL : API_URL;
    $.ajax({
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        type: config.type,
        url: URL + "/" + config.url,
        dataType: "JSON",
        data: config.data,
        contentType: false,
        cache: false,
        processData: false,

        success: function (data) {
            hasLoadingButton ? stopLoadingState(config.loadingButton) : "";
            config["onSuccess"](data);
        },
        error: function (data) {
            console.log(config);
            hasLoadingButton ? stopLoadingState(config.loadingButton) : "";
            if (config.onError) {
                config["onError"](data.responseJSON);
            } else {
                swal(
                    "Sorry!",
                    "The process could not be completed. Please try again later.",
                    "error"
                );
            }
        },
    });
}

function startLoadingState(element) {
    $(element).attr("disabled", true).append(spinner);
}

function stopLoadingState(element) {
    $("#spinner").remove();
    $(element).removeAttr("disabled");
}

function continueToRegistration() {
    goto("#home-banner");
    $("#invoice").modal("hide");
}

export const theInvoice = ` 
    <div class="modal-dialog modal-dialog-centered modal-lg" id="theinvoice" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Invoice</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body modal-table">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Particulars</th>
                            <th scope="col">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Unpaid Tax</th>
                            <td>{{unpaidTax}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Unpaid Tax Penalty</th>
                            <td>{{unpaidTaxPenalty}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Renewal Charge</th>
                            <td>{{renewalCharge}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Renewal Fine</th>
                            <td>{{renewalFine}}</td>
                        </tr>
                    
                        {{#if modelTaxAmount}}
                            <tr>
                                <th scope="row">Model Tax Amount</th>
                                <td>{{modelTaxAmount}}</td>
                            </tr>

                            <tr>
                                <th scope="row">Model Tax Fine</th>
                                <td>{{modelTaxFine}}</td>
                            </tr>
                        {{/if}}
                        <tr>
                            <th scope="row">Insurance Amount</th>
                            <td>{{insuranceAmount}}</td>
                        </tr>
                        <tr>
                            <th scope="row">
                                Service Charge (including VAT)
                            </th>
                            <td>{{serviceCharge}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Tax (current year)</th>
                            <td>{{tax}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                        <th scope="row">Total Bill Amount</th>
                        <td>Rs. {{total}}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" onclick="(() => { 
                    $('#invoice').modal('hide');
                    $('#vehicle-renewal-modal').modal('show');

                })()">Continue To Registration</button>
                <!--<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>-->
            </div>
        </div>
    </div>
`;

const spinner = `
<div class="spinner-border spinner-border-sm" id="spinner" role="status">
  <span class="sr-only">Loading...</span>
</div>
`;
